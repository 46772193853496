
/**
 * MQ3 AQ3 2023 Pdf view
 *
 * @author Huelya Celik
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  name: "Mq3Aq32023Pdf"
})
export default class Mq3Aq32023Pdf extends Vue {}
